import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { RouteSlug } from './models/route-slug.enum';

export const APP_ROUTES: Routes = [
  {
    path: RouteSlug.SSR_REDIRECT,
    loadChildren: () => import('./ssr-redirect/ssr-redirect.component').then((m) => m.SsrRedirectModule),
  },
  {
    path: '',
    loadChildren: () => import('./pages/core/core.component').then((m) => m.CoreModule),
  },
];

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(APP_ROUTES, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: QuicklinkStrategy,
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
