import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppStateSetCookiesAllowed } from '../app.state';
import { OnSaveCookiesSettings } from '../models/tokens';

@Injectable({
  providedIn: 'root',
})
export class SaveCookiesSettingsService implements OnSaveCookiesSettings {
  constructor(private readonly _store: Store) {}

  public onSaveCookiesSettings(unnecessaryChecked: boolean): void {
    this._store.dispatch(new AppStateSetCookiesAllowed({ cookiesAllowed: unnecessaryChecked }));
  }
}
