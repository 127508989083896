import { InjectionToken } from '@angular/core';
import { getDocument, getWindow } from 'ssr-window';

export const GOOGLE_GAT_GTAG = new InjectionToken<string>('GOOGLE_GAT_GTAG');
export const GOOGLE_TAG_MANAGER_ID = new InjectionToken<string>('googleTagManagerId');
export const WINDOW = new InjectionToken<Window>('WINDOW', {
  factory: () => getWindow(),
});
export const DOCUMENT = new InjectionToken<Document>('DOCUMENT', {
  factory: () => getDocument(),
});
export const SERVICES_API = new InjectionToken<string>('SERVICES_API');
export const SERVICES_CUSTOMER_TOKEN = new InjectionToken<() => string>('SERVICES_CUSTOMER_TOKEN');
export const ARE_COOKIES_ALLOWED = new InjectionToken<() => boolean>('ARE_COOKIES_ALLOWED');

export interface OnSaveCookiesSettings {
  onSaveCookiesSettings(unnecessaryChecked: boolean): void;
}
export const ON_SAVE_COOKIES_SETTINGS = new InjectionToken<OnSaveCookiesSettings>('ON_SAVE_COOKIES_SETTINGS');
