import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { EMPTY } from 'rxjs';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { AuthGuard } from './pages/core/guards/auth.guard';
import { RECAPTCHA_LANGUAGE, RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { getDocument, getWindow } from 'ssr-window';
import { SsrGuard } from './pages/core/guards/ssr.guard';
import { APP_COOKIES_KEY, APP_JWT_TOKEN } from './app.consts';
import { AppState, AppStateLogout, AppStateSetUserData } from './app.state';
import {
  ARE_COOKIES_ALLOWED,
  DOCUMENT,
  GOOGLE_GAT_GTAG,
  GOOGLE_TAG_MANAGER_ID,
  ON_SAVE_COOKIES_SETTINGS,
  SERVICES_API,
  SERVICES_CUSTOMER_TOKEN,
  WINDOW,
} from './models/tokens';
import { UserService } from './services/user.service';
import { JwtInterceptorService } from './services/jwt-interceptor.service';
import { SaveCookiesSettingsService } from './services/save-cookies-settings.service';

const LOCAL_STORAGE_STATE_VALUES = [APP_JWT_TOKEN, APP_COOKIES_KEY];

registerLocaleData(localePl);

const NGXS_MODULES = [
  NgxsModule.forRoot([AppState], {
    developmentMode: !environment.production,
  }),
  NgxsRouterPluginModule.forRoot(),
  NgxsFormPluginModule.forRoot(),
  NgxsLoggerPluginModule.forRoot({
    logger: console,
    collapsed: false,
    disabled: environment.production,
    // disabled: true,
  }),
  NgxsStoragePluginModule.forRoot({
    storage: StorageOption.LocalStorage,
    key: [...LOCAL_STORAGE_STATE_VALUES],
  }),
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({
      appId: 'serverApp',
    }),
    BrowserAnimationsModule,
    BrowserTransferStateModule,
    HttpClientModule,
    RecaptchaV3Module,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 15000,
      positionClass: 'toast-top-right',
    }),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ...NGXS_MODULES,
  ],
  providers: [
    AuthGuard,
    SsrGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true,
    },
    { provide: GOOGLE_TAG_MANAGER_ID, useValue: environment.gtm },
    { provide: GOOGLE_GAT_GTAG, useValue: environment.gtag },
    {
      provide: ARE_COOKIES_ALLOWED,
      useFactory: (store: Store) => () => store.selectSnapshot(AppState.cookiesAllowed),
      deps: [Store],
    },
    {
      provide: SERVICES_CUSTOMER_TOKEN,
      useFactory: (store: Store) => () => store.selectSnapshot(AppState.token),
      deps: [Store],
    },
    {
      provide: ON_SAVE_COOKIES_SETTINGS,
      useClass: SaveCookiesSettingsService,
    },
    { provide: WINDOW, useValue: getWindow() },
    { provide: DOCUMENT, useValue: getDocument() },
    { provide: SERVICES_API, useValue: environment.api },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaV3SiteKey,
    },
    { provide: RECAPTCHA_LANGUAGE, useValue: 'pl' },
    { provide: LOCALE_ID, useValue: 'pl-PL' },
    {
      provide: APP_INITIALIZER,
      useFactory: (userService: UserService, store: Store) => async () => {
        const { token } = store.selectSnapshot(AppState);
        if (!token) {
          return EMPTY;
        }
        return await userService
          .getMe()
          .toPromise()
          .then((user) => {
            store.dispatch(new AppStateSetUserData({ user }));
          })
          .catch((reason) => {
            if (reason?.status === 403) {
              setTimeout(() => {
                store.dispatch(new AppStateLogout());
              }, 200);
            }
          });
      },
      deps: [UserService, Store],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
