import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { TransferState } from '@angular/platform-browser';
import { isPlatformServer } from '@angular/common';
import { RouteSlug } from '../../../models/route-slug.enum';
import { STATE_KEY_SSR_REDIRECT } from '../../../models/state-keys';

@Injectable({
  providedIn: 'root',
})
export class SsrGuard implements CanActivate {
  constructor(
    @Inject(PLATFORM_ID)
    private readonly _platformId: string,
    private readonly _router: Router,
    private readonly _transferState: TransferState,
  ) {}

  public canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const ssrBlockedUrl: UrlTree = this._router.parseUrl(`/${RouteSlug.SSR_REDIRECT}`);
    if (isPlatformServer(this._platformId)) {
      this._transferState.set(STATE_KEY_SSR_REDIRECT, state.url);
      return ssrBlockedUrl;
    }
    return this._transferState.hasKey(STATE_KEY_SSR_REDIRECT) ? ssrBlockedUrl : true;
  }
}
