import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUser } from '../models/user';
import { IUserUpdateDto } from '../models/user-update-dto';
import { SERVICES_API, SERVICES_CUSTOMER_TOKEN } from '../models/tokens';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    @Inject(SERVICES_API)
    private _servicesApi: string,
    @Inject(SERVICES_CUSTOMER_TOKEN)
    private _servicesCustomerToken: () => string,
    private readonly _httpClient: HttpClient,
  ) {}

  /**
   * Get customer account details
   */
  public getMe(): Observable<IUser> {
    return this._httpClient.get<IUser>(`${this._servicesApi}platform/designer/me`);
  }

  /**
   * Logout designer. Destroy session.
   */
  public logout(): Observable<void> {
    return this._httpClient.get<void>(`${this._servicesApi}platform/logout`);
  }

  /**
   * Update customer account details
   * @param userEditDto
   */
  public updateMe(userEditDto: IUserUpdateDto): Observable<void> {
    return this._httpClient.put<void>(`${this._servicesApi}my-amica/customer`, userEditDto, {
      headers: {
        CustomerAuthorization: `Token ${this._servicesCustomerToken()}`,
      },
    });
  }
}
