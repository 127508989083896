import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { UserService } from '../../../services/user.service';
import { AppState, AppStateLogout, AppStateSetUserData } from '../../../app.state';
import { RouteSlug } from '../../../models/route-slug.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private readonly _store: Store, private readonly _userService: UserService) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const { token, user } = this._store.selectSnapshot(AppState);
    // todo check tokenExpiredAt

    // not logged
    if (!token) {
      return this._goToLoginPage(state);
    }

    if (!user) {
      return this._userService.getMe().pipe(
        catchError((err) => {
          if (err?.status === 403) {
            this._store.dispatch(new AppStateLogout());
          }
          return throwError(err);
        }),
        map((user) => {
          this._store.dispatch(new AppStateSetUserData({ user }));
          return !!user;
        }),
      );
    }

    // todo check date of expiration of token
    return of(true);
  }

  private _goToLoginPage(state: RouterStateSnapshot): Observable<boolean> {
    return of(false).pipe(
      tap(() => {
        this._store.dispatch(new Navigate([RouteSlug.AUTH, RouteSlug.LOGIN], { returnUrl: state.url }));
      }),
    );
  }
}
