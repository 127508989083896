export enum RouteSlug {
  MAIN = '',
  CHANGE_PASSWORD = 'change-password',
  AGREEMENT = 'agreement',
  MY_ACCOUNT = 'my-account',

  AUTH = 'auth',
  LOGIN = 'login',
  REGISTER = 'register',
  ACTIVATE_ACCOUNT = 'activate-account',
  RESET_PASSWORD = 'reset-password',
  CHANGE_FORGOTTEN_PASSWORD = 'change-forgotten-password',
  NOT_FOUND_PAGE = '404',
  SSR_REDIRECT = 'ssr-redirect',
}
