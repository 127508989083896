<div class="amf-modal-content amf-confirm-action-modal">
  <div class="col-12 pl-sm-5 pr-sm-5 pl-3 pr-3 pt-5">
    <h4 class="c-text">{{ title }}</h4>
    <button (click)="closePopup()" class="close-btn" type="button">&times;</button>
  </div>

  <div class="col-12 pl-sm-5 pr-sm-5 pl-3 pr-3 mt-md-4 mt-2">
    <p class="p-20">
      {{ question }}
    </p>
  </div>

  <div class="col-12 pl-sm-5 pr-sm-5 pl-3 pr-3 mt-sm-4 mt-3 pb-3 text-sm-right text-center">
    <button (click)="onConfirm()" class="button button-white button-normal am-border-red me-sm-3 mb-2">Tak</button>
    <button (click)="closePopup()" class="button button-red button-normal am-border-white mb-2">Nie</button>
  </div>
</div>
