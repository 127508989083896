import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UserService } from './services/user.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Navigate } from '@ngxs/router-plugin';
import { IUser } from './models/user';
import { RouteSlug } from './models/route-slug.enum';

export class AppStateSetJwtToken {
  public static readonly type = '[AppState] set jwt token';

  constructor(public readonly payload: { token: string }) {}
}

export class AppStateLogout {
  public static readonly type = '[AppState] logout';
}

export class AppStateGetUserData {
  public static readonly type = '[AppState] get user data';
}

export class AppStateSetUserData {
  public static readonly type = '[AppState] set user data';

  constructor(public readonly payload: { user: IUser }) {}
}

export class AppStateSetCookiesAllowed {
  public static readonly type = '[AppState] set cookies allowed';

  constructor(public readonly payload: { cookiesAllowed: boolean }) {}
}

export interface AppStateModel {
  loading: boolean;
  token: string | null;
  user: IUser | null;
  cookiesAllowed: boolean | null;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    loading: false,
    token: '',
    user: null,
    cookiesAllowed: null,
  },
})
@Injectable()
export class AppState {
  constructor(private readonly _userService: UserService) {}

  @Selector()
  public static token({ token }: AppStateModel): string | null {
    return token;
  }

  @Selector()
  public static user({ user }: AppStateModel): IUser | null {
    return user;
  }

  @Selector()
  public static cookiesAllowed({ cookiesAllowed }: AppStateModel): boolean | null {
    return cookiesAllowed;
  }

  @Selector()
  public static loading({ loading }: AppStateModel): boolean {
    return loading;
  }

  @Action(AppStateSetJwtToken)
  public setJwtToken({ patchState }: StateContext<AppStateModel>, { payload: { token } }: AppStateSetJwtToken): void {
    patchState({ token });
  }

  @Action(AppStateSetCookiesAllowed)
  public setCookiesAllowed(
    { patchState }: StateContext<AppStateModel>,
    { payload: { cookiesAllowed } }: AppStateSetCookiesAllowed,
  ): void {
    patchState({ cookiesAllowed });
  }

  @Action(AppStateSetUserData)
  public setUserData(
    { patchState }: StateContext<AppStateModel>,
    { payload: { user } }: AppStateSetUserData,
  ): Observable<AppStateModel> {
    return of(patchState({ user }));
  }

  @Action(AppStateGetUserData)
  public getUserData({ patchState }: StateContext<AppStateModel>): Observable<IUser> {
    patchState({ loading: true });
    return this._userService.getMe().pipe(
      catchError((err) => {
        patchState({ loading: false });
        return throwError(err);
      }),
      tap((user) => {
        patchState({ user, loading: false });
      }),
    );
  }

  @Action(AppStateLogout)
  public logout({ patchState, dispatch, getState }: StateContext<AppStateModel>): Observable<void> {
    const { token } = getState();
    if (!token) {
      patchState({ user: null, token: null });
      dispatch(new Navigate([RouteSlug.AUTH, RouteSlug.LOGIN]));
      return of();
    }
    patchState({ loading: true });
    return this._userService.logout().pipe(
      catchError((err) => {
        patchState({ user: null, token: null, loading: false });
        dispatch(new Navigate([RouteSlug.AUTH, RouteSlug.LOGIN]));
        return throwError(err);
      }),
      tap(() => {
        patchState({ user: null, token: null, loading: false });
        dispatch(new Navigate([RouteSlug.AUTH, RouteSlug.LOGIN]));
      }),
    );
  }
}
