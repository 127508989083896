import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  templateUrl: './confirm-action-modal.component.html',
  styleUrls: ['./confirm-action-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class ConfirmActionModalComponent {
  constructor(
    private readonly _ngbActiveModal: NgbActiveModal,
    private readonly _store: Store,
    private readonly _ngxSpinnerService: NgxSpinnerService,
  ) {}

  private _title: string;

  get title(): string {
    return this._title;
  }

  @Input()
  set title(value: string) {
    this._title = value;
  }

  private _question: string;

  get question(): string {
    return this._question;
  }

  @Input()
  set question(value: string) {
    this._question = value;
  }

  public closePopup(): void {
    this._ngbActiveModal.close(false);
  }

  public onConfirm(): void {
    this._ngbActiveModal.close(true);
  }
}

@NgModule({
  declarations: [ConfirmActionModalComponent],
  imports: [CommonModule],
  exports: [ConfirmActionModalComponent],
})
export class ConfirmActionModalModule {}
