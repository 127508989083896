import { AfterViewInit, Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { isPlatformServer } from '@angular/common';
import { ConfirmActionModalComponent } from './components/confirm-action-modal/confirm-action-modal.component';
import { interval } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { WINDOW } from './models/tokens';

@Component({
  selector: 'amf-root',
  template: ` <router-outlet></router-outlet>`,
})
@UntilDestroy()
export class AppComponent implements OnInit, AfterViewInit {
  @HostListener('window:beforeinstallprompt', ['$event'])
  public onBeforeInstallPrompt(e: Event): void {
    e.preventDefault();
  }

  constructor(
    private readonly _ngbModal: NgbModal,
    private readonly _router: Router,
    @Inject(PLATFORM_ID)
    private readonly _platformId: string,
    @Inject(WINDOW)
    private readonly _window: Window,
    private readonly _swUpdate: SwUpdate,
  ) {
    this._checkForUpdateIfServiceWorkerEnabled();
  }

  public async ngOnInit(): Promise<void> {
    this._setRouterSubscription();
    await this._setServiceWorkerUpdateSubscription();
  }

  private _setRouterSubscription(): void {
    this._router.events.pipe(untilDestroyed(this)).subscribe((routeEvent) => {
      if (routeEvent instanceof NavigationEnd) {
        this._ngbModal.dismissAll();
      }
    });
  }

  private async _setServiceWorkerUpdateSubscription(): Promise<void> {
    if (isPlatformServer(this._platformId)) {
      return;
    }
    this._swUpdate.available.pipe(untilDestroyed(this), distinctUntilChanged(isEqual)).subscribe((available) => {
      this._openConfirmDeleteAccountModalWindow()
        .then((result: boolean) => {
          if (result) {
            this._window.location.reload();
          }
        })
        .catch();
    });
    this._checkForUpdateIfServiceWorkerEnabled();
    interval(30000).subscribe(() => {
      this._checkForUpdateIfServiceWorkerEnabled();
    });
  }

  private _openConfirmDeleteAccountModalWindow(): Promise<boolean> {
    const modalWindowOptions: NgbModalOptions = {
      backdrop: true,
      keyboard: true,
      centered: true,
      modalDialogClass: 'amf-modal-content',
    };

    const modalRef = this._ngbModal.open(ConfirmActionModalComponent, modalWindowOptions);
    modalRef.componentInstance.title = 'Nowa wersja dostępna.';
    modalRef.componentInstance.question = `Czy chcesz załadować nową wersję aplikacji?`;
    return modalRef.result;
  }

  public ngAfterViewInit(): void {
    this._checkForUpdateIfServiceWorkerEnabled();
  }

  private _checkForUpdateIfServiceWorkerEnabled(): void {
    if (this._swUpdate.isEnabled) {
      this._swUpdate.checkForUpdate().then();
    }
  }
}
